///////////////////////////////////////////////////////////
// Copyright year update

const yearEl = document.querySelector('.year');
const currentYear = new Date().getFullYear();
yearEl.textContent = currentYear;

///////////////////////////////////////////////////////////
// Smooth scrolling animation

const allLinks = document.querySelectorAll('a:link');

allLinks.forEach(function (link) {
  link.addEventListener('click', function (e) {
    const href = link.getAttribute('href');

    if (href.startsWith('#')) {
      e.preventDefault();
    }

    // Scroll back to top link
    if (href === '#') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    // Scroll to other links
    if (href !== '#' && href.startsWith('#')) {
      const sectionEl = document.querySelector(href);
      sectionEl.scrollIntoView({ behavior: 'smooth' });
    }
  });
});

///////////////////////////////////////////////////////////
// Sticky navigation

const sectionHeroEl = document.querySelector('.section-hero');

if (sectionHeroEl && 'IntersectionObserver' in window) {
  const obsStickyNav = new IntersectionObserver(
    function (entries) {
      const ent = entries[0];

      // Remember to update this media query threshold based on CSS
      const mediaQueryCheck = window.matchMedia('(max-width: 39em)');

      if (ent.isIntersecting === false && !mediaQueryCheck.matches) {
        document.body.classList.add('sticky');
        sectionHeroEl.classList.add('section-hero-sticky');
      }

      if (ent.isIntersecting === true && !mediaQueryCheck.matches) {
        document.body.classList.remove('sticky');
        sectionHeroEl.classList.remove('section-hero-sticky');
      }
    },
    {
      //In the viewport
      root: null,
      threshold: 0,
      rootMargin: '-80px',
    }
  );
  obsStickyNav.observe(sectionHeroEl);
}

///////////////////////////////////////////////////////////
// Fade in

const fadeElements = [
  document.querySelector('.hero-img'),
  document.querySelector('.vision-1a'),
  document.querySelector('.vision-1b'),
  document.querySelector('.vision-2a'),
  document.querySelector('.vision-2b'),
  document.querySelector('.vision-3a'),
  document.querySelector('.vision-3b'),
  document.querySelector('.vision-row-highlight'),
  document.querySelector('.section-cta'),
];

const fadeIn = function (target) {
  if ('IntersectionObserver' in window) {
    const obsFadeIn = new IntersectionObserver(
      function (entries) {
        const ent = entries[0];
        if (ent.isIntersecting) {
          target.classList.add('fade-in');
          target.classList.remove('opacity-zero');
        }
      },
      {
        //In the viewport
        root: null,
        threshold: 0.2,
      }
    );
    obsFadeIn.observe(target);
  } else {
    target.classList.remove('opacity-zero');
  }
};

fadeElements.forEach((el) => {
  if (el) fadeIn(el);
});

///////////////////////////////////////////////////////////
// Send mail form

function sendMail() {
  const fullName = document.getElementById('full-name').value;
  const email = document.getElementById('email').value;

  const submitForm = document.getElementById('submit-form');
  submitForm.removeAttribute('href');

  if ((fullName !== '') & (email !== '')) {
    submitForm.href = `mailto:getvenn2021@gmail.com?subject=Please add me to Venn's waitlist!&body=Hi team,%0d%0a%0d%0aI'd love to be an early user of Venn!%0d%0a%0d%0aEmail: ${email}%0d%0a%0d%0aThanks,%0d%0a${fullName}`;
  }
}

///////////////////////////////////////////////////////////
// Beta - select OS

const iOSSection = document.getElementById('ios');
const androidSection = document.getElementById('android');

function selectIOS() {
  androidSection.innerHTML = '';
  androidSection.classList.remove('section-os');

  iOSSection.classList.add('section-os');
  iOSSection.innerHTML = `
    <h2 class="heading-secondary">Join on iOS</h2>
    <div class="os-container">
      <h2 class="heading-secondary beta-margin-bottom">1.</h2>
      <div class="beta-os-step">
        <p class="beta-hero-body-text beta-margin-top">Download TestFlight, Apple's beta testing platform, from the App Store.</p>
        <a class="btn btn--solid" target="_blank" href="https://apps.apple.com/ca/app/testflight/id899247664">Download TestFlight</a>
      </div>
      <h2 class="heading-secondary beta-margin-bottom">2.</h2>
      <div class="beta-os-step">
        <p class="beta-hero-body-text beta-margin-top">Join the Venn beta program on TestFlight.</p>
        <a class="btn btn--solid" target="_blank" href="https://testflight.apple.com/join/jS1tBOeQ">Join the Venn Beta</a>
      </div>
      <h2 class="heading-secondary beta-margin-bottom">3.</h2>
      <div class="beta-os-step">
        <p class="beta-hero-body-text beta-margin-top">Use your six-digit referral code to create an account on Venn. Enjoy!</p>
      </div>
    </div>
  `;
}

function selectAndroid() {
  iOSSection.innerHTML = '';
  iOSSection.classList.remove('section-os');

  androidSection.classList.add('section-os');
  androidSection.innerHTML = `
    <h2 class="heading-secondary">Join on Android</h2>
    <div class="os-container">
      <h2 class="heading-secondary beta-margin-bottom">1.</h2>
      <div class="beta-os-step">
        <p class="beta-hero-body-text beta-margin-top">Download and install Venn from the Google Play Store.</p>
        <a href='https://play.google.com/store/apps/details?id=com.vennapp'>
          <img 
            alt='Get it on Google Play' 
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
            class="app-store-badge"
          />
        </a>
      </div>
      <h2 class="heading-secondary beta-margin-bottom">2.</h2>
      <div class="beta-os-step">
        <p class="beta-hero-body-text beta-margin-top">Use your six-digit referral code to create an account on Venn. Enjoy!</p>
      </div>
    </div>
  `;
}

///////////////////////////////////////////////////////////
// Fixing flexbox gap property in some Safari versions

function checkFlexGap() {
  var flex = document.createElement('div');
  flex.style.display = 'flex';
  flex.style.flexDirection = 'column';
  flex.style.rowGap = '1px';

  flex.appendChild(document.createElement('div'));
  flex.appendChild(document.createElement('div'));

  document.body.appendChild(flex);
  var isSupported = flex.scrollHeight === 1;
  flex.parentNode.removeChild(flex);
  console.log(isSupported);

  if (!isSupported) document.body.classList.add('no-flexbox-gap');
}
checkFlexGap();
